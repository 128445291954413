
<template lang='pug'>

  //*** POP UP : SEND to WASH
  div
    vs-popup(classContent='popup-example' title='ข้อมูลผูติดต่อ' :active.sync="popPanel")
      //*** หัวข้อ
      //- vs-row.row-form(vs-w='12' style="background-color:rgb(248, 248, 248)" )
      //-   vs-col.form-label.py-4.px-6(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
      //-     h4(style="font-size:1.7rem;") ข้อมูลเพิ่มร้านซัก/ซ่อม

      //*** เส้นแบ่ง
      //- vs-divider.mb-0(v-if="sendingAction===1"  )

      .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%; ')


        //*** ชื่อร้านซัก/ซ่อม
        vs-row(vs-w='12' style='margin-top:20px;')
          vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') ชื่อผู้ติดต่อ
          vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 9995 !important;')
            vs-input(style='display:block; width:80%;' v-model="name")
        //*** ที่อยู่
        vs-row.row-form(vs-w='12' style='margin-top:20px;' )
          vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') ที่อยู่
          vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 10 !important;')
            .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%; ')
              vs-row.row-form(vs-w='12')
                vs-textarea(style='display:block; width:80%;' v-model='address')
              vs-row.row-form(vs-w='12')
                div(style='font-size:11px;  color: #4a90e2;') (เหลือความยาวตัวอักรษร {{remaining(100,address)}} )

        //*** เบอร์ร้าน
        vs-row(vs-w='12' style='margin-top:20px;')
          vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') เบอร์โทร
          vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 9995 !important;')
            vs-input(style='display:block; width:80%;' v-model="phone")

        //*** ข้อมูลเพิ่มเติม
        vs-row.row-form(vs-w='12' style='margin-top:20px;' )
          vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') ข้อมูลเพิ่มเติม
          vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 10 !important;')
            .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%; ')
              vs-row.row-form(vs-w='12')
                vs-textarea(style='display:block; width:80%;' v-model='description')
              vs-row.row-form.pb-2(vs-w='12')
                div(style='font-size:11px;  color: #4a90e2;') (เหลือความยาวตัวอักรษร {{remaining(100,description)}} )

        //*** status
        vs-row.pb-6(vs-w='12' style='margin-top:20px;')
          vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') สถานะ
          vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 9995 !important;')
            div( @click="status = !status" :class="[status === true ? 'choose' : 'notChoose']")
                span(v-if="status === true") เปิด
                span(v-if="status === false") ปิด


        //*** ปุ่ม
        vs-row(vs-w='12' vs-type='flex' vs-justify='flex-end' style=' padding : 20px 20px; background-color: #F8F8F8; ')
          vs-button.btn-success( style='width:150px; margin-right: 20px;', @click="save()"  ) บันทึก

          vs-button(style='background-color:#4A4A4A !important; width:120px;' @click='closePanel()' type='filled') ยกเลิก





</template>

<script>

import { BEmbed } from 'bootstrap-vue'
import Multiselect from "vue-multiselect";
import Prism from "vue-prism-component";
import vSelect from "vue-select";
// import HotelDatePicker from "vue-hotel-datepicker";
import Datepicker from "vuejs-datepicker";
import shapeFormat from "../../mixins/shapeFormat.js";
// import ProductService from "./Services/ProductService";
import Selection1 from "./Components/Selection1";
// import UpdateStockSidebar from './Components/UpdateStockSidebar.vue'

// const productService = new ProductService();


export default {
  props: {
    panelPopup: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [shapeFormat],
  data() {
    return {

     name: "",
     address: "",
     phone: "",
     description: "",
     status: true,

     thePanel: false,
      //*** begin : WASHING SHOP list
      washingShopPanel: false,
      washingShop: "",
      washingShopList: [],
      infoNote: "",
      washingDate:{},
      displayItems: [],
      sendingItems:[],
      sendingReq:{},
      sendingAction: 0, // 1: ส่งซัก, 2: รับชุดซักกลับ, 3: ไม่ส่งซัก

      //*** end : WASHING SHOP list


    };
  },


  async created() {


  },

  async mounted() {
    if( this.addOrUpdate === 2 )
    {
      this.name = this.updateItem.wnfShopName;
      this.address = this.updateItem.wnfShopAddress;
      this.phone = this.updateItem.wnfShopPhone;
      this.description = this.updateItem.wnfShopDescription;
      this.status = this.updateItem.status;
    }
  },

  computed: {
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    popPanel :{
      set(){
        this.closePanel();
      },
      get(){
        // console.log("this.$store.getters['wnfShop/popPanel']", this.$store.getters["wnfShop/popPanel"]);
        return this.$store.getters["wnfShop/popPanel"];
      }

    },
    addOrUpdate : {
      set(){
      },
      get(){
        // console.log(" ADD OR UPDATE COMPUTED ", this.$store.getters["wnfShop/add_or_update"]);
        return this.$store.getters["wnfShop/add_or_update"];
      }
    },
    updateItem : {
      set(){
      },
      get(){
        // console.log(" ADD OR UPDATE COMPUTED ", this.$store.getters["wnfShop/add_or_update"]);
        return this.$store.getters["wnfShop/updateItem"];
      }
    }
  },

  watch: {
    description(){
      // this.remaining(100,this.description) < 0
      // console.log('this.description >>>>>>> ',this.description.length);
      if(this.remaining(100,this.description) < 0 ){
        this.description.substring(0, 99)
      }

    }
    // popPanel : function(){
    //   console.log("!!! WATCH popPanel ",this.popPanel);
    // }
  }
,
  methods:{
    async save(){



      if(this.name === null || this.name === "" || this.name === undefined ){
        this.$swal({
          icon: 'warning',
          title: 'โปรดระบุข้อมูลให้ครบถ้วน',
          text: "ชื่อร้านเป็นข้อมูลที่ระบบต้องการ",
        });

        return;
      }

      if(this.phone === null || this.phone === "" || this.phone === undefined ){
        this.$swal({
          icon: 'warning',
          title: 'โปรดระบุข้อมูลให้ครบถ้วน',
          text: "เบอร์โทรเป็นข้อมูลที่ระบบต้องการ",
        });

        return;
      }

      console.log('this.status >>> ',this.status);


      var item = {
        "branchId" : this.getCurrentBranch.branchId,
        "name": this.name,
        "address": this.address,
        "phone": this.phone,
        "description": this.description,
        "status": this.status
      };

      this.$vs.loading();

      if(this.addOrUpdate === 1){
        await this.$store.dispatch("wnfShop/add",item);
      }
      if(this.addOrUpdate === 2){
        item.wnfShopId = this.updateItem.wnfShopId;
         await this.$store.dispatch("wnfShop/update",item);
      }
      this.$vs.loading.close();

      this.closePanel();
      // await this.$store.dispatch("wnfShop/fetchList",item);
    },
    closePanel(){
      this.$store.commit("wnfShop/POP_PANEL",false);
      this.name = "";
      this.address = "";
      this.description = "";
      this.phone = "";
      this.additionalInfo = "";
      this.$store.commit("wnfShop/ADD_OR_UPDATE",0);
      this.$store.commit("wnfShop/UPDATE_ITEM",{});
      // this.panelPopup = false;
    }
  },
  components: {
    Multiselect,
    BEmbed,
    Prism,
    Datepicker,
    // VueRangedatePicker,
    // VueHotelDatepicker,
    "v-select": vSelect,
    // HotelDatePicker,
    Selection1

  },

};
</script>

<style>

.vs-dropdown--menu{
  width: 170px;
}

.multiselect__content-wrapper .multiselect-leave-active{
  z-index: 99999 !important;
}

/* .vs__search{
  display: none !important;
} */
.clear-btn:hover{
   text-decoration: underline;
}
/* .vs-pagination--nav {
  display: none;
} */

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.checkqueuelist {
  margin-top: 0;
}
.input-date {
  width: 100% !important;
}

.dropdown-toggle .clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none;
}
.selected-tag {
  font-size: 12px;
}

/* VUE HOTEL DATEPICKUP */
.previous-arrow .offset-2 .disabled {
  display: block !important;
}

/* .calendar {
  position: relative !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
  z-index: 999 !important;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

.zoomimage {
  width: 400px;
}
.datepicker__tooltip {
  display: none !important;
}

.demo-alignment {
  vertical-align: middle;
}

li {
  margin: 0 !important;
}

@media only screen and (max-width: 578px) {
  #main-background {
    width: 100% !important;
    padding: 0 !important;
  }
  #controller-panel {
    width: 91% !important;
  }
}
</style>
